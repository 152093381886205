import { render, staticRenderFns } from "./OfferCardMediumLoader.vue?vue&type=template&id=3b8d7e86"
import script from "./OfferCardMediumLoader.vue?vue&type=script&lang=js"
export * from "./OfferCardMediumLoader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Development\\kang\\front_kang_v5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b8d7e86')) {
      api.createRecord('3b8d7e86', component.options)
    } else {
      api.reload('3b8d7e86', component.options)
    }
    module.hot.accept("./OfferCardMediumLoader.vue?vue&type=template&id=3b8d7e86", function () {
      api.rerender('3b8d7e86', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/offer/OfferCardMediumLoader.vue"
export default component.exports