var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("base-paragraph", [
        _vm._v(_vm._s(_vm.trans.get("payment.enter_bank_info"))),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "space-y-2" },
        [
          _c("base-text-input", {
            attrs: {
              placeholder: _vm.trans.get("payment.diagonal.iban"),
              label: _vm.trans.get("payment.diagonal.iban"),
              autocomplete: "off",
              outlined: "",
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "building-columns"] },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("base-text-input", {
            attrs: {
              placeholder: _vm.trans.get("payment.diagonal.first_name"),
              label: _vm.trans.get("payment.diagonal.first_name"),
              autocomplete: "off",
              outlined: "",
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "circle-user"] },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("base-text-input", {
            attrs: {
              placeholder: _vm.trans.get("payment.diagonal.last_name"),
              label: _vm.trans.get("payment.diagonal.last_name"),
              autocomplete: "off",
              outlined: "",
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "circle-user"] },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("base-text-input", {
            attrs: {
              placeholder: _vm.trans.get("payment.diagonal.address"),
              label: _vm.trans.get("payment.diagonal.address"),
              autocomplete: "off",
              outlined: "",
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "location-dot"] },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("base-text-input", {
            attrs: {
              placeholder: _vm.trans.get("payment.diagonal.street"),
              label: _vm.trans.get("payment.diagonal.street"),
              outlined: "",
              disabled: "",
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "road"] },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-2 gap-2" },
            [
              _c("base-text-input", {
                attrs: {
                  placeholder: _vm.trans.get("payment.diagonal.number"),
                  label: _vm.trans.get("payment.diagonal.number"),
                  outlined: "",
                  disabled: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "hashtag"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm._v(" "),
              _c("base-text-input", {
                attrs: {
                  placeholder: _vm.trans.get("payment.diagonal.postal_code"),
                  label: _vm.trans.get("payment.diagonal.postal_code"),
                  outlined: "",
                  disabled: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "mailbox"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("base-text-input", {
            attrs: {
              placeholder: _vm.trans.get("payment.diagonal.city"),
              label: _vm.trans.get("payment.diagonal.city"),
              outlined: "",
              disabled: "",
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "city"] },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("base-text-input", {
            attrs: {
              placeholder: _vm.trans.get("payment.diagonal.country"),
              label: _vm.trans.get("payment.diagonal.country"),
              outlined: "",
              disabled: "",
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("span", { staticClass: "vti__flag inline-block de" }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-paragraph",
        { staticClass: "mt-3" },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.trans.get("payment.diagonal.conditions")) +
              "\n        "
          ),
          _c(
            "base-link",
            {
              on: {
                click: function ($event) {
                  return _vm.toggleMoreInformationModal(true)
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("payment.diagonal.more_information")) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-2 gap-4 mt-5" },
        [
          _c(
            "base-button",
            { attrs: { outlined: "" }, on: { click: _vm.cancel } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("generic.cancel")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("base-button", { on: { click: _vm.createDiagonal } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.trans.get("payment.diagonal.validate_iban")) +
                "\n        "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showMoreInformationModal
        ? _c(
            "base-modal",
            {
              attrs: { small: "" },
              on: {
                close: function ($event) {
                  return _vm.toggleMoreInformationModal(false)
                },
              },
            },
            [
              _c("base-title", [
                _vm._v(
                  _vm._s(
                    _vm.trans.get(
                      "payment.diagonal.more_information_modal.title"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("base-paragraph", [
                _vm._v(
                  _vm._s(
                    _vm.trans.get(
                      "payment.diagonal.more_information_modal.text"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid grid-cols-1 gap-4 mt-5" },
                [
                  _c(
                    "base-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.toggleMoreInformationModal(false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.trans.get("generic.back")) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }