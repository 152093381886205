import { render, staticRenderFns } from "./ModalFailedPayPerUsePayment.vue?vue&type=template&id=24d5e240"
import script from "./ModalFailedPayPerUsePayment.vue?vue&type=script&lang=js"
export * from "./ModalFailedPayPerUsePayment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Development\\kang\\front_kang_v5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24d5e240')) {
      api.createRecord('24d5e240', component.options)
    } else {
      api.reload('24d5e240', component.options)
    }
    module.hot.accept("./ModalFailedPayPerUsePayment.vue?vue&type=template&id=24d5e240", function () {
      api.rerender('24d5e240', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/modals/ModalFailedPayPerUsePayment.vue"
export default component.exports