var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "payment-method-paypal" },
        [
          _c("div", { staticClass: "flex-none" }, [
            _c("img", {
              staticClass: "h-8 inline-block",
              attrs: {
                src: _vm.assetManager.getImageAsset(
                  "payment/brands/paypal.png"
                ),
                alt: "Paypal",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-grow overflow-ellipsis overflow-hidden" },
            [_c("span", [_c("strong", [_vm._v(_vm._s(_vm.paypal.email))])])]
          ),
          _vm._v(" "),
          _c(
            "base-button",
            {
              staticClass: "flex-none",
              attrs: { color: "danger", outlined: "" },
              on: {
                click: function ($event) {
                  return _vm.toggleDeleteModal(true)
                },
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fal", "trash-can"] },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showDeleteModal
        ? _c(
            "base-modal",
            {
              attrs: { small: "" },
              on: {
                close: function ($event) {
                  return _vm.toggleDeleteModal(false)
                },
              },
            },
            [
              _c("base-title", [
                _vm._v(_vm._s(_vm.trans.get("payment.remove_paypal"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "payment-method-paypal" }, [
                _c("div", { staticClass: "flex-none" }, [
                  _c("img", {
                    staticClass: "h-8 inline-block",
                    attrs: {
                      src: _vm.assetManager.getImageAsset(
                        "payment/brands/paypal.png"
                      ),
                      alt: "Paypal",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex-grow overflow-ellipsis overflow-hidden",
                  },
                  [
                    _c("span", [
                      _c("strong", [_vm._v(_vm._s(_vm.paypal.email))]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid grid-cols-1 gap-4 mt-5" },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { loading: _vm.loading, color: "danger" },
                      on: { click: _vm.deletePaypal },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fal", "trash-can"] },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.trans.get("payment.remove")) +
                          "\n            "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }