<template>
    <div>
        <base-paragraph>{{ trans.get('payment.enter_bank_info') }}</base-paragraph>

        <div class="space-y-2">
            <base-text-input :placeholder="trans.get('payment.diagonal.iban')"
                             :label="trans.get('payment.diagonal.iban')"
                             autocomplete="off"
                             outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'building-columns']"/>
                </template>
            </base-text-input>
            <base-text-input :placeholder="trans.get('payment.diagonal.first_name')"
                             :label="trans.get('payment.diagonal.first_name')"
                             autocomplete="off"
                             outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'circle-user']"/>
                </template>
            </base-text-input>
            <base-text-input :placeholder="trans.get('payment.diagonal.last_name')"
                             :label="trans.get('payment.diagonal.last_name')"
                             autocomplete="off"
                             outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'circle-user']"/>
                </template>
            </base-text-input>
            <base-text-input :placeholder="trans.get('payment.diagonal.address')"
                             :label="trans.get('payment.diagonal.address')"
                             autocomplete="off"
                             outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'location-dot']"/>
                </template>
            </base-text-input>
            <base-text-input :placeholder="trans.get('payment.diagonal.street')"
                             :label="trans.get('payment.diagonal.street')"
                             outlined
                             disabled>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'road']"/>
                </template>
            </base-text-input>

            <div class="grid grid-cols-2 gap-2">
                <base-text-input :placeholder="trans.get('payment.diagonal.number')"
                                 :label="trans.get('payment.diagonal.number')"
                                 outlined
                                 disabled>
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'hashtag']"/>
                    </template>
                </base-text-input>
                <base-text-input :placeholder="trans.get('payment.diagonal.postal_code')"
                                 :label="trans.get('payment.diagonal.postal_code')"
                                 outlined
                                 disabled>
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'mailbox']"/>
                    </template>
                </base-text-input>
            </div>

            <base-text-input :placeholder="trans.get('payment.diagonal.city')"
                             :label="trans.get('payment.diagonal.city')"
                             outlined
                             disabled>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'city']"/>
                </template>
            </base-text-input>
            <base-text-input :placeholder="trans.get('payment.diagonal.country')"
                             :label="trans.get('payment.diagonal.country')"
                             outlined
                             disabled>
                <template #icon>
                    <span class="vti__flag inline-block de"></span>
                </template>
            </base-text-input>
        </div>

        <base-paragraph class="mt-3">
            {{ trans.get('payment.diagonal.conditions') }}
            <base-link @click="toggleMoreInformationModal(true)">
                {{ trans.get('payment.diagonal.more_information') }}
            </base-link>
        </base-paragraph>

        <div class="grid grid-cols-2 gap-4 mt-5">
            <base-button @click="cancel" outlined>
                {{ trans.get('generic.cancel') }}
            </base-button>
            <base-button @click="createDiagonal">
                {{ trans.get('payment.diagonal.validate_iban') }}
            </base-button>
        </div>

        <base-modal v-if="showMoreInformationModal" @close="toggleMoreInformationModal(false)" small>
            <base-title>{{ trans.get('payment.diagonal.more_information_modal.title') }}</base-title>

            <base-paragraph>{{ trans.get('payment.diagonal.more_information_modal.text') }}</base-paragraph>

            <div class="grid grid-cols-1 gap-4 mt-5">
                <base-button @click="toggleMoreInformationModal(false)">
                    {{ trans.get('generic.back') }}
                </base-button>
            </div>
        </base-modal>
    </div>
</template>

<script>
import BaseButton from "../elements/BaseButton.vue";
import BaseParagraph from "../elements/BaseParagraph.vue";
import BaseTextInput from "../elements/BaseTextInput.vue";
import BaseTitle from "../elements/BaseTitle.vue";
import BaseLink from "../elements/BaseLink.vue";

export default {
    name: "PaymentMethodDiagonalForm",
    components: {
        BaseLink,
        BaseButton,
        BaseParagraph,
        BaseTextInput,
        BaseTitle,
    },
    methods: {
        toggleMoreInformationModal(showModal) {
            if (showModal !== undefined) {
                this.showMoreInformationModal = showModal;
            } else {
                this.showMoreInformationModal = !this.showMoreInformationModal;
            }
        },
        createDiagonal() {
            this.loading = true;
            alert('TODO : createDiagonal');
        },
        cancel() {
            this.$emit('cancel');
        }
    },
    data: function () {
        return {
            showMoreInformationModal: false,
            loading: false,
        }
    },
}
</script>
