var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "payment-methods" },
    [
      _c(
        "div",
        [
          _c("base-title", [
            _vm._v(_vm._s(_vm.trans.get("payment.payment_methods"))),
          ]),
          _vm._v(" "),
          _c("base-paragraph", [
            _vm._v(_vm._s(_vm.trans.get("payment.current_payment_methods"))),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.hasCard && !_vm.hasPaypal && !_vm.hasDiagonal
        ? [
            _c("base-paragraph", [
              _vm._v(_vm._s(_vm.trans.get("payment.no_payment_method"))),
            ]),
          ]
        : [
            _vm.hasCard
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("base-title", { staticClass: "flex-grow" }, [
                        _vm._v(_vm._s(_vm.trans.get("payment.credit_card"))),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("img", {
                          staticClass: "h-6 inline-block",
                          attrs: {
                            src: _vm.assetManager.getImageAsset(
                              "payment/brands/mastercard.png"
                            ),
                            alt: "Mastercard",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "h-6 inline-block",
                          attrs: {
                            src: _vm.assetManager.getImageAsset(
                              "payment/brands/visa.png"
                            ),
                            alt: "Visa",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "h-6 inline-block",
                          attrs: {
                            src: _vm.assetManager.getImageAsset(
                              "payment/brands/americanexpress.png"
                            ),
                            alt: "Americanexpress",
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "space-y-2" },
                    _vm._l(_vm.paymentMethods.cards, function (card) {
                      return _c("payment-method-card", {
                        key: card.id,
                        attrs: { card: card },
                      })
                    }),
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPaypal
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("base-title", { staticClass: "flex-grow" }, [
                        _vm._v(_vm._s(_vm.trans.get("payment.paypal"))),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("img", {
                          staticClass: "h-6 inline-block",
                          attrs: {
                            src: _vm.assetManager.getImageAsset(
                              "payment/brands/paypal_large.png"
                            ),
                            alt: "Paypal",
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "space-y-2" },
                    _vm._l(_vm.paymentMethods.paypals, function (paypal) {
                      return _c("payment-method-paypal", {
                        key: paypal.id,
                        attrs: { paypal: paypal },
                      })
                    }),
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.config.get("payment-methods.diagonal.enabled")
              ? [
                  _vm.hasDiagonal
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("base-title", { staticClass: "flex-grow" }, [
                              _vm._v(
                                _vm._s(_vm.trans.get("payment.bank_transfer"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("img", {
                                staticClass: "h-6 inline-block",
                                attrs: {
                                  src: _vm.assetManager.getImageAsset(
                                    "payment/brands/sepa.png"
                                  ),
                                  alt: "Sepa",
                                },
                              }),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "space-y-2" },
                          _vm._l(
                            _vm.paymentMethods.diagonals,
                            function (diagonal) {
                              return _c("payment-method-diagonal", {
                                key: diagonal.id,
                                attrs: { diagonal: diagonal },
                              })
                            }
                          ),
                          1
                        ),
                      ])
                    : _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c("base-title", { staticClass: "flex-grow" }, [
                                _vm._v(
                                  _vm._s(_vm.trans.get("payment.bank_transfer"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("img", {
                                  staticClass: "h-6 inline-block",
                                  attrs: {
                                    src: _vm.assetManager.getImageAsset(
                                      "payment/brands/sepa.png"
                                    ),
                                    alt: "Sepa",
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.showPaymentMethodDiagonalForm
                            ? _c(
                                "base-link",
                                {
                                  attrs: { color: "dark" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.togglePaymentMethodDiagonalForm(
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.trans.get(
                                          "payment.diagonal.add_iban"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showPaymentMethodDiagonalForm
                            ? _c("payment-method-diagonal-form", {
                                on: {
                                  cancel: function ($event) {
                                    return _vm.togglePaymentMethodDiagonalForm(
                                      false
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }